.lds-dual-ring {
    display: inline-block;
    position: absolute;
    margin: -5px 10px;
    /*width: 80px;
    height: 80px;*/
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 0px 5px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fe4e20 transparent #fe4e20 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}



@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.morestorebtn:hover .lds-dual-ring:after{
    border-color: #fff transparent #fff transparent;
}

.white:after {
    border-color: #fff transparent #fff transparent;
}