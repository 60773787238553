* {
  font-family: "Cairo", sans-serif;
}

html {
  height: 100%;
  background-color: #f5f5f5;
}

body {
  overflow-x: hidden;
  background-color: #f5f5f5;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

a :hover :active {
  color: inherit;
  /* blue colors for links too */
  text-decoration: none;
  /* no underline */
}

.center {
  margin: 100px auto;
  text-align: center;
}
.container {
  display: flex;
}

.navbar_p_top {
  background: linear-gradient(269.57deg, #d02c6c -5.15%, #fe4e20 99.48%);
  color: #fff;
  height: 80px;
  display: flex;
  justify-content: space-between;
}

.search_main {
  background: #edf0f7;
  padding: 5px;
  border-radius: 3px;
  /*width: 50%;*/
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.search_main input {
  background: #edf0f7;
  border: none;
  margin: 0;
  font-size: 16px;
  padding: 0 5px;
  height: auto;
  outline: none;
}

.search_main select {
  background: #edf0f7;
  color: #495057;
  font-size: 16px;
  border-radius: 0;
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
  -moz-appearance: none;
}

.search_main img {
  padding: 5px;
  width: 35px;
  height: 35px;
}

.main_login_button {
  background: #edf0f7;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  height: 50px;
  align-items: center;
}

.main_login_button button {
  background: #edf0f7;
}

.signup_btn {
  border: 0;
}

.login_btn {
  border: 0;
}

.menu_nav_ {
  display: flex;
  justify-content: space-around;
}

.menu_bottom {
  height: 45px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.menu_bottom ul {
  list-style: none;
  display: flex;
}

.menu_bottom li {
  margin: 10px;
}

.menu_bottom ul {
  margin: 0;
  padding: 0;
}

.menu_bottom_drop_serche {
  background: #fff;
  border: 0;
  height: 100%;
  width: 60px;
  background: red;
}

.menu_bottom_drop {
  background: #fff;
  border: 0;
  height: 100%;
}

.header {
  width: 100%;
  /*background: #f0edff;*/
  position: relative;
  text-align: center;
  overflow: hidden;
}
.header h2 span {
  color: #d63030;
}
.header p {
  line-height: 2;
  font-size: 17px;
}

.bg-rotate-header {
  position: absolute;
  top: -100px;
  bottom: 0;
  left: -100px;
  right: -100px;
  background: #f0edff;
  z-index: 0;
  transform: rotate(3deg);
}
.navbar_p {
  position: relative;
  z-index: 2;
}
.header_img {
  padding: 20px;
}

.header_img img {
  width: 300px;
  padding: 20px;
}

.header_body {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: right;
  padding: 50px;
}

.header_r button {
  background: linear-gradient(269.57deg, #f34633 -5.15%, #db335c 99.48%);
  border: 0;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  color: #fff;
  min-width: 200px;
}

.header_container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
}

.main_header {
  display: flex;
  flex-wrap: wrap;
}

.header_img {
  width: 40%;
  text-align: center;
  min-width: 150px;
}

.header_body {
  width: 60%;
  min-width: 150px;
}

.main_header_home {
  width: 80%;
  position: relative;
  z-index: 1;
  display: flex;
  margin: 0 auto;
}

.header_l {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  padding: 20px;
}
.header_l img {
  height: 280px;
  width: 280px;
}

.header_r {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding: 20px;
}

.main_services {
  width: 80%;
  padding-top: 30px;
  padding-bottom: 30px;

  margin: 0 auto;
  display: flex;
}

.service {
  width: 33.33%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.service p {
  text-align: center;
  color: #999999;
}

.service h5 {
  margin-top: 10px;

  color: #7288a3;
  font-weight: bold;
}

.headline {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.color_span {
  color: #ed413c;
}

.stors_home {
  background: linear-gradient(269.57deg, #d02c6c -5.15%, #fe4e20 99.48%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  text-align: center;
  padding-bottom: 30px;
}

.store_home_item {
  width: 90%;
  height: 90px;
  background: #fff;
}

.store_container {
  width: 80%;
  margin: 0 auto;
}

.morestorebtnHome {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  color: #fff;
  padding: 10px 15px;
  margin-top: 30px;
}

.morestorebtn {
  border: 1px solid #e2e6ec;
  box-sizing: border-box;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  margin-top: 30px;
  color: #7288a3;
  width: 100%;
}

.morestorebtn:hover {
  background: linear-gradient(269.57deg, #d02c6c -5.15%, #fe4e20 99.48%);
  color: #fff;
}

.logo_footer {
  background: #7288a3;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.store_item {
  background: #fff;
  margin-bottom: 30px;
  border: 1px solid #e2e6ec;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.store_item_home {
  background: #fff;
  margin-right: 15px;
  margin-left: 15px;
  border: 1px solid #e2e6ec;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.recv_value {
  color: #ed413c;
  font-weight: bold;
}

.direction_flex {
  align-items: flex-start;
  text-align: left;
}

.headline_stors {
  color: #fff;
}

.login_section {
  width: 100%;
  overflow-y: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 30px;
  background-color: #f5f5f5;
}

.shop_btn {
  background: #d63061;
  min-height: 35px;
  border: 0;
  color: #fff;
  border-radius: 3px;
}

.shop_btn.ckeck-btn {
  min-height: 45px;
}

.check-product {
  display: inline;
}

.image_section {
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_section img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.head_store {
  position: absolute;
  width: 100%;
  height: 200px;
  mix-blend-mode: normal;
  opacity: 0.8;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
}

/**
* rani zadt hadi 3la khatar marakch khadam form 
*/

.login_section input,
.login_section button,
.login_section select {
  width: 100%;
  outline: none;
}

.main_login {
  display: flex;
  flex-direction: column;
}

.login_box {
  width: 400px;
  background: #fff;
  position: relative;
  border-bottom: 1px solid #e0e5eb;
}

.login_box_head {
  height: 120px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-size: contain;
}

.login_box_form {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.signup_box_form {
  height: 60%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.recovery_box_form {
  height: 60%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.login_box_link {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/*
* rani zad hadi 
*/

.login_box_link label,
.login_box_link a,
.login_box_link a:hover {
  color: #7288a3;
  font-size: 14px;
}

/*
* rak nasi les autres type 
*/

.login_btn_box,
.button {
  background: linear-gradient(269.57deg, #f34633 -5.15%, #db335c 99.48%);
  padding: 10px;
  color: #fff;
  border: 0;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.button {
  width: 100%;
}

form input,
.cash-request input,
.cash-request select,
.cash-request textarea {
  background: #edf0f7;
  color: #495057;
  border-radius: 3px;
  border: 0;
  padding: 10px;
  border: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 45px;
}

form select,
form textarea,
.form-control,
.form-control,
.form-control {
  background: #edf0f7;
  border-radius: 3px;
  border: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  height: 45px;
}

form input:hover,
form input:focus,
form input:active,
form textarea:hover,
form textarea:focus,
form textarea:active,
form button:focus,
form select:focus,
.form-control:hover,
.form-control:active,
.form-control:focus {
  color: #495057;
  background-color: #edf0f7;
  border: 0;
  outline: 0;
  box-shadow: none;
}

.h_signup_box {
  height: auto;
}

.h_recovery_box {
  height: 300px;
}

.h_login_box {
  height: auto;
}

button:focus {
  outline: none !important;
}

.row {
  width: 100%;
}

.profile_user {
  background: red;
  margin: 10px;
  background: #ffffff;
  border: 1px solid #e0e5eb;
  box-sizing: border-box;
  border-radius: 10px;
}

.profile_body {
  background: red;
  margin: 10px;
}

.head_card_user {
  height: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: contain;
}

.image-pofile {
  width: 80px;
  height: 80px;
  margin: 5px 0;
  cursor: pointer;
}

.active .profile_btn {
  background: linear-gradient(269.57deg, #f34633 -5.15%, #db335c 99.48%);
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}

.profile_btn a {
  text-decoration: inherit;
  color: red;
}

.profile_btn {
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #7288a3;
  background: #edf0f7;
}

.icon_profile_item {
  width: 30%;
}

.title_profile_item {
  width: 70%;
  display: flex;
  justify-content: flex-start;
}

.list-group-item a:hover {
  text-decoration: none;
}

.login_box_form .alert p {
  margin-bottom: 5px;
}

.head_card_user {
  color: #fff;
}

.head_card_user {
  color: #fff;
}

.main_body_profile {
  width: 100%;
  border: 1px solid #e0e5eb;
  background: #fff;
}

.inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn_actions {
  border: 0;
  background: #edf0f7;
}

.head_tab {
  background: #edf0f7;
}

.head_tab th {
  font-size: 13px;
  font-weight: normal;
  color: #7288a3;
  text-align: center;
}

.PasswordInput {
  background: #edf0f7;
  display: flex;
  border-radius: 3;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 20px;
  /*height: 45px;*/
}

.PasswordInput button {
  background: #edf0f7;
  border: 0;
  width: 15%;
}
.PasswordInput input {
  width: 75%;
  margin: 0;
}

.card {
  border-radius: 0;
  border: 1px solid #e0e5eb;
}

.card-header {
  border-bottom: 1px solid #e0e5eb;
}

.store_name {
  margin-top: 10px;
  margin-bottom: 10px;
}

.store_cash {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #d63061;
}
.store_cash span {
  color: #808080;
  font-size: 13px;
  display: block;
  margin-bottom: 3px;
}
.store_button {
  margin-top: 10px;
  margin-bottom: 10px;
}

.coupons_head {
  width: 100%;
  min-width: 170px;
  background-size: contain;

  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.store_logo_coupons {
  width: 80%;
  /*height: 60px;*/
  background: #fff;
  position: relative;
  max-width: 100%;
  top: -20px;
  margin: 0 auto;
  border: 1px solid #e0e5eb;
  overflow: hidden;
}

.CodeCoupons {
  background: #e0e5eb;
  width: 80%;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_menu {
  width: 20%;
}
form input[type="radio"] {
  width: 24px;
  height: 24px;
  margin: 0 2px;
}

.search_menu {
  min-width: 40%;
  display: flex;
  justify-content: center;
}

.menu_hm {
  display: none;
}

.menu_hm_btn {
  background: 0;
  border: 0;
  color: #fff;
  z-index: 999;
}

.menu_hm_btn_search {
  background: 0;
  border: 0;
  color: #fff;
  z-index: 999;
}

.menu_side {
  z-index: 9999;
  background: linear-gradient(269.57deg, #d02c6c -5.15%, #fe4e20 99.48%);
  width: 300px;
  height: 100%;
  position: absolute;
  position: fixed;
  overflow: hidden;
  right: -300px;
  transition: 300ms;
}

.menuback {
  background: #1d1d1d4d;
  width: 100%;
  height: 100%;
  position: absolute;
  position: fixed;
  z-index: 99;
  display: none;
}

.side_logo_menu {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  animation-name: example;
  animation-duration: 4s;
  animation-delay: 2s;
  flex-direction: column;
}

.side_logo_menu img {
  margin-bottom: 25px;
}

.close_navside {
  width: 100%;
  padding: 9px;
  display: flex;
  justify-content: space-between;
}

.close_navside button {
  border: 0;
  background: 0;
}

.side_logo_menu_logo {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side_menu_menu {
  width: 100%;
  height: 100%;
  background: #fff;
}

.swal2-actions .btn {
  margin: 0 5px;
}

.btn-google,
.btn-facebook {
  padding: 10px;
  margin: 5px 0;
}
.btn-google span,
.btn-facebook span {
  margin: 0 5px;
  color: #fff;
}
.btn-google {
  background-color: #db4437;
}
.btn-facebook {
  background-color: #4267b2;
}

.cash-request {
  padding: 10px;
}

.cash-request input:hover,
.cash-request input:focus,
.cash-request input:active,
.cash-request select:hover,
.cash-request select:focus,
.cash-request select:active,
.cash-request textarea:hover,
.cash-request textarea:focus,
.cash-request textarea:active {
  color: #495057;
  background-color: #edf0f7;
  border: 0;
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgb(148 148 148 / 25%);
}

.static-row {
  text-align: center;
}
.static-row a {
  display: block;
  color: #000;
  position: relative;
}
.static-row a:hover {
  text-decoration: none;
}
.static-row img {
  width: 60px;
  height: 60px;
}
.static-row p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  max-width: 200px;
}
.static-row .amount {
  position: absolute;
  top: -5px;
  right: 12px;
  background: #ec403fe3;
  color: #fff;
  padding: 3px 3px;
  border-radius: 5px;
  font-size: 15px;
}

.table {
  overflow-y: hidden;
}

.coupons_head h6 {
  overflow: hidden;
  /*white-space: nowrap;*/
  text-overflow: ellipsis;
  padding: 0 3px;
}

.toast {
  transition: 200ms;
  position: absolute;
  bottom: 25%;
  /*left: 10%;*/
  right: -10%;
}

.coupons-store img {
  width: 100%;
}

.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

.table::-webkit-scrollbar {
  width: 12px;
  height: 10px;
  background-color: #f5f5f5;
}

.table::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #bebebe;
}

.dropdown-menu {
  z-index: 100;
}
.dropdown-bg {
  position: fixed;
  display: none;
  top: 0;
  height: 100%;
  width: 100%;
}
.dropdown-bg.show {
  display: block;
  z-index: 99;
}

.btn-bg {
  background: linear-gradient(269.57deg, #f34633 -5.15%, #db335c 99.48%);
  border-radius: 5px !important;
  border: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
}

.separator {
  padding: 0 2px;
  border-left: 1.5px solid;
  border-color: #d0d6dc;
  height: 100%;
  /*margin: 5% 0;*/
}

.sep {
  border-left: 1px solid;
  border-color: #f34631;
  height: 100%;
}

.amounts {
  text-align: center;
  height: 140px;
}

/**** respon **/
/**
* mobile
*/

@media screen and (max-width: 992px) {
  .header_l {
    width: 100%;
  }
  .header_r {
    width: 100%;
  }
  .login_menu {
    display: none;
  }
  .menu_hm {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .main_header_home {
    flex-direction: column;
  }
  .main_services {
    flex-direction: column;
  }
  .service {
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .logo_menu {
    display: none;
  }
  .searchmobilesection {
    width: 100%;
  }
}

/**
* big screen
*/

@media screen and (min-width: 992px) {
  .login_menu {
    display: block;
  }
  .logo_menu {
    display: block;
  }
  .menu_hm {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .table-search {
    display: block;
  }
}

.contact_text {
  min-height: 150px;
}

.btn_submit {
  background: linear-gradient(269.57deg, #f34633 -5.15%, #db335c 99.48%);
  color: #fff;
  width: 100%;
}
.btn_submit:hover,
.btn_submit:active,
.btn_submit:focus {
  color: #fff;
}

.contact_item {
  width: 100%;
  height: 40px;
}

.contact_items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact_item {
  display: flex;
  align-items: center;
}

.contact_value {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contact_value h5 {
  margin: 0;
  padding: 0;
}

.icon {
  width: 10%;
  display: flex;
  justify-content: center;
}

.main_footer {
  background-color: rgb(1, 22, 39);
  text-align: center;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.about p {
  line-height: 2;
  font-size: 16px;
}
.about .img {
  text-align: center;
}
